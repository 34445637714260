<template>
    <div style="margin: 1px;">
        <div style="height: 10px">
            <span v-if="currencyIndex > 0"
            class="graph-numeric-label">{{ currencyIndex
                }}</span>
            <span style="width: 35%; float: right;padding: 5px;font-weight: bold;font-size: medium; color: white; border-radius: 5%; background-color: slategrey; text-align: center">{{ currency
                }}</span>
        </div>
        <div>
            <bar-chart style="margin-top: 5px;"
                       :plugins="plugins"
                        :maintain-aspect-ratio="maintainAspectRatio"
                       :width="chartWidth"
                       :height="chartHeight"
                       :handler="handler"
                       css-classes="bar-chart"
                       y-scale-label="Value"
                       :chart-data="currencyCollection" :title="currency"></bar-chart>
        </div>
        <div v-if="singleChart && currency !== 'FX Majors'">
            <br/>
            <span
                  style="width: 15%; padding: 5px;font-weight: bold;font-size: medium; color: white; border-radius: 5%; background-color: slategrey; text-align: center"
                  @click="displayFxMajors">Go To FX Majors</span>
        </div>

    </div>

</template>

<script>

import BarChart from "./BarChart";
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
    name: "CurrencyMonitor",
    components: {BarChart},
    props: {
        singleChart: Boolean,
        maintainAspectRatio: Boolean,
        currency: String,
        currencyIndex: Number,
        chartWidth: {
            type: Number,
            default: 400
        },
        chartHeight: {
            type: Number,
            default: 400
        },
        currencyCollection: {
            type: Object,
            default: () => {
            }
        },
        handler: {
            type: Function,
            default: () => {
            }
        },
        displayFxMajors: {
            type: Function,
            default: () => {
            }
        }
    },
    data: function () {
        return {
            plugins: [ChartDataLabels]
        }
    }
}
</script>

<style lang="scss" scoped>
.chartjs-size-monitor {
    position: relative !important;
}

.graph-numeric-label {
    font-weight: bold;
    font-size: medium; 
    color: $color-01; 
    border-radius: 50%; 
    background-color: $color-20;

    display: inline-block;
    width: 25px;
    height: 25px;
    text-align: center;
}
</style>
